<template>
    <div>
        <b-overlay :show="loadingAdd" :opacity="0.6"> 
            <b-row>
                    <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
                        <div class="mt-3 mb-2">
                            <router-link :to="{ name: 'planning-offers-shifts', params: {}}">
                                <b-icon icon="arrow-left" class="mr-1"></b-icon>
                            {{trans('to-services',285)}}
                            </router-link>
                        </div>
                            
                            <h1 class="mt-4 mb-4">{{trans('sh-create-shift',292)}}</h1>

                            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                                <div>
                                        <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                                        <span class="align-middle">{{trans('sh-service-created-successfully',292)}}</span>
                                </div>
                                
                            </b-alert>

                            <ValidationObserver ref="form">
                                <b-row class="match-height">
                                    <b-col md="6" class="pr-md-2 mb-sm-3 mb-md-0">
                                        
                                        <b-card class="mb-3" v-if="step == 1">
                                            <div class="app-card-profile-title mb-3">{{trans('sh-select-department',292)}}</div>
                                            <offers-shifts-add-select-department-component :key="refresh_departments" :loading="loadingAdd" :planData.sync="planData"/>

                                            <b-alert variant="warning" show class="mt-3" v-if="planData.department != null && planData.department.id_cao_data_main == null">
                                                <b-row class="d-flex align-items-center">
                                                    <b-col start cols="auto">
                                                        <b-icon icon="file-earmark-plus-fill" font-scale="3"></b-icon>  
                                                    </b-col>
                                                    <b-col>
                                                        <div class="font-weight-600 text-base">{{trans('sh-set-cao-action',292)}}</div>
                                                        <div class="text-small">{{trans('sh-set-cao-at-specific-level',292)}}</div>
                                                    </b-col>
                                                    <b-col end cols="auto">
                                                        <router-link :to="'/clients/department/' + planData.department.value">
                                                            <app-button type="primary">
                                                                {{trans('pl-open-department-profile',294)}}
                                                            </app-button>
                                                        </router-link>                                          
                                                    </b-col>
                                                </b-row>
                                            </b-alert> 
                                        </b-card>

                                    </b-col>

                                    <b-col md="6" class="pl-md-2">

                                        <b-card class="mb-3" v-if="step == 1">
                                            <div class="app-card-profile-title mb-3">{{trans('functions',280)}}</div>
                                            <app-multi-select v-model="planData.ids_function_data_main" :label="trans('sh-function',292)" :showMultiOptions="true" :additionalLabel="trans('sh-alternative-functions',292)" labelPosition="top" :addButtonLabel="trans('sh-add-function',292)" type="getDepartmentsFunctions" validatorRules="required" :validatorName="trans('sh-function',292)" :validatorCustomMessage="{'required': trans('sh-select-minimum-one-function',293)}" :args.sync="planData.department" :isAllOption="false" :refreshIndex.sync="planData.refreshFunctions" :key="refresh" :disabled="planData.department == null || planData.department.id_cao_data_main == null" requiredMark/>
                                        </b-card>

                                    </b-col>
                                </b-row>

                                <offers-shifts-add-plan-component class="mb-3" :planData="planData"  v-if="planData.ids_function_data_main.length > 0 && step == 1" />  

                            </ValidationObserver>

                            <offers-shifts-add-result-list-component v-if="step == 2" :planData="planData" :shifts="shifts"/>

                            <div class="required-label ml-2" v-if="step == 1"><span class="required">* </span>{{ trans('sh-required-field', 292) }}</div>
                            <div class="mt-3 mb-3" >
                                <app-button type="primary" :loading="loadingAdd" @click="add()" v-if="step == 1">
                                    {{trans('sh-create-shift',292)}}
                                </app-button>
                                <app-button type="primary" :loading="loadingAdd" @click="addAgain()" v-if="step == 2">
                                    {{trans('pl-create-another-service',294)}}
                                </app-button>
                                <app-button type="primary" :url="{ name: 'planning-offers-shifts', params: {}}" v-if="step == 2">
                                    {{trans('to-services',285)}}
                                </app-button>
                            </div>
                    </b-col>
            </b-row>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay> 
    </div>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import OffersShiftsAddSelectDepartmentComponent from './Components/OffersShiftsAddSelectDepartmentComponent.vue';
import OffersShiftsAddPlanComponent from './Components/OffersShiftsAddPlanComponent.vue';
import AppMultiSelect from '@core/components/AppMultiSelect.vue';
import OffersShiftsAddResultListComponent from './Components/OffersShiftsAddResultListComponent.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import moment from "moment";

export default {
    components: {
        OffersShiftsAddSelectDepartmentComponent,
        OffersShiftsAddPlanComponent,
        ValidationObserver,
        AppMultiSelect,
        OffersShiftsAddResultListComponent
    },

    mounted() {
        if(this.$route.params.id_shift_data_main != null){
            this.getDataToCopy(this.$route.params.id_shift_data_main);
        }
        
    },

    data() {
        return {
            step: 1,
            planData : {
                location: null,
                person: null, 
                client_location: null,
                department: null,
                function: null,
                date_from:null,
                start_time: null,
                end_time: null,
                date_to:null,
                shift_code: null,
                pause_type: null,
                
                per_week: 1,
                repeat: "0",
                repeat_days: [],
                repeat_type: 1,
    
                users_args: {
                    id_client_data_main: JSON.parse(localStorage.getItem('user-portal')).id_client_data_main,
                    id_location_data_main: null,
                    id_office_data_location: null,
                }, 
                id_shift_const_daytime: null,
                additional_functions:{
                    items:{
                        list:[],
                        total: 0,
                        refresh: 0,
                    },
                    page:1,
                },     
                refreshFunctions:0, 
                refreshContactPersons: 0,
                refreshQualifications: 0,
                refreshTargetgroup: 0,
                ids_function_data_main: [],
                ids_qualification_const_type: [],
                id_office_data_location: null,
                description: null,
                ids_group_const_type: [],
                default_employment_data_main: null,
                id_employment_const_type: null
            },
            functions_additional: [],
            loadingAddAndClose: false,
            loadingAdd: false,
            show_success_alert: false,
            success_dismiss_count_down: 0,
            id : -1,
            shifts: [],
            refresh: 0,
            refresh_departments: 0,
            alert_class: new AppAlerts(),
            get_copy_data: false,
        };
    },

    watch:{
        show_success_alert(val){
            this.success_dismiss_count_down = 3;
        },

        "planData.ids_function_data_main": {
            handler: function(newVal, oldVal) {
                    if(this.id != this.planData.ids_function_data_main[0]){
                        this.addAdditionals();
                        this.id = this.planData.ids_function_data_main[0];
                    }else{
                        this.checkQualifications();
                    }
            },
            deep: true,
        },

        
        "planData.department": {
            handler: function(newVal, oldVal) {
                if(!this.loadingAdd){
                    this.planData.ids_function_data_main = [];
                    this.functions_additional = [];
                }
                
                if(this.planData.department != null && this.planData.department.id_cao_data_main != null){
                    this.refresh++;
                }
            },
            deep: true,
        },

        "planData.location":{
            handler: function(newVal, oldVal) {
                    if(oldVal != null && (newVal.value != oldVal.value)){
                        this.planData.ids_function_data_main = [];
                        this.functions_additional = [];
                    }
                    
            },
            deep: true,
        },

        "$route.params.id_shift_data_main": {
            handler: function(val) {
                
                if (val > 0) {
                    this.getDataToCopy(val);
                }                
            },
        }
    },

    methods: {
        add() {
            
             this.$refs.form.validate().then((success) => {
                 if (success) {
                     this.loadingAdd = true;
                     
                     axios
                         .post("shifts/add", {
                             id_department_data_main: this.planData.department.value, 
                             ids_function_data_main: this.planData.ids_function_data_main,
                             ids_qualification_const_type: this.planData.ids_qualification_const_type,
                             ids_group_const_type: this.planData.ids_group_const_type,
                             id_cao_data_main: this.planData.department.id_cao_data_main,
                             id_location_data_main: this.planData.client_location.value, 
                             id_office_data_location: this.planData.id_office_data_location,
                             date_from: this.planData.date_from, 
                             hour_from: this.planData.start_time, 
                             hour_to: this.planData.end_time,
                             
                             repeat_type: this.planData.repeat == 0 ? this.planData.repeat : this.planData.repeat_type,
                             repeat_days: JSON.stringify(this.planData.repeat_days),
                             repeat_count: this.planData.per_week, 
                             repeat_date_to: this.planData.date_to, 

                             description: this.planData.description,
                             cao_source: this.planData.department.cao_source, 
                             id_shift_const_daytime: this.planData.id_shift_const_daytime,
                             id_client_data_location: this.planData.client_location.id_client_data_location,
                             id_person_data_main: this.planData.person != null ? this.planData.person.id : null,
                             shift_code: this.planData.shift_code,
                             pause_type: this.planData.pause_type != null ? this.planData.pause_type.value : 0,
                             id_employment_const_type: this.planData.id_employment_const_type != null ? this.planData.id_employment_const_type : null
                         })
                         .then((res) => {
                             this.shifts = res.data;
                         })
                         .catch((error) => {
                             console.error(`error during request: ${error}`);
                         }).finally(() => {
                             this.loadingAdd = false;
                            this.step = 2;
                         });

                        document.getElementById('app').scrollIntoView({
                                                                        behavior: "smooth",
                                                                        block: "start",
                                                                    });

                 }else{

                     setTimeout(() => {
                         const errors = Object.entries(this.$refs.form.refs)
                             .map(([key, value]) => ({
                                 key,
                                 value,
                             }))
                             .filter((error) => {
                                 if (!error || !error.value || !error.value.failedRules) return false;
                                 return Object.keys(error.value.failedRules).length > 0;
                             });
                         if (errors && errors.length > 0) {
                             this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                 behavior: "smooth",
                                 block: "center",
                             });
                         }
                     }, 100);

                 }

             }); 
        },

        getDataToCopy(id){
            this.loadingAdd = true;
            var setPerson = false;
            this.get_copy_data = true;
            axios
                        .post("shifts/getCopyShiftData", {
                            id_shift_data_main: id

                        })
                        .then((res) => {
                            
                            this.planData.client_location = { 
                                                              name: res.data.client_location_name,
                                                              value: res.data.id_location_data_main, 
                                                              id_client_data_main : res.data.id_client_data_main,
                                                              client_confirmation: res.data.client_confirmation,
                                                              import_procedure: res.data.import_procedure,
                                                              registration_process: res.data.registration_process,
                                                              registration_tool: res.data.registration_tool,
                                                              send_notification: res.data.send_notification,
                                                              id_client_data_parent: res.data.id_client_data_parent,
                                                              id_client_data_location: res.data.id_client_data_location
                                                            };
                            
                            this.planData.department = {
                                                        value: res.data.id_department_data_main,
                                                        id_department_data_main: res.data.id_department_data_main,
                                                        name: res.data.department_name,
                                                        id_cao_data_main: res.data.id_cao_data_main,
                                                        cao_source: res.data.cao_source,
                                                        additional_functions: res.data.additional_functions,
                                                        zzp_preferences: res.data.zzp_preferences,
                                                        default_qualifications: res.data.default_qualifications,
                                                        functions_qualifications: res.data.functions_qualifications

                            }


                            this.planData.users_args = {
                                                            id_client_data_main : res.data.id_client_data_main,
                                                            id_office_data_location : res.data.id_office_data_location,
                                                            id_location_data_main : res.data.id_location_data_main
                                                            
                                                        };

                            this.planData.ids_function_data_main = res.data.function_additional ? JSON.parse(res.data.function_additional) : [];
                            this.function_additional = res.data.function_additional ? JSON.parse(res.data.function_additional) : [];
                            this.planData.ids_qualification_const_type = res.data.ids_qualification_const_type ? JSON.parse(res.data.ids_qualification_const_type) : [];
                            this.planData.ids_group_const_type = res.data.ids_group_const_type ? JSON.parse(res.data.ids_group_const_type) : [];

                            this.planData.default_employment_data_main = res.data.zzp_preferences ? res.data.zzp_preferences : null;
                            this.planData.id_employment_const_type = res.data.id_employment_const_type ? res.data.id_employment_const_type : null;

                            this.planData.ids_function_data_main.unshift(res.data.id_function_data_main);

                            if(res.data.id_person_data_main){
                                setPerson = true;
                                this.planData.person = {
                                                            avatar: res.data.avatar,
                                                            function_name: res.data.person_function_name,
                                                            id: res.data.id_person_data_main,
                                                            name : res.data.person_name,
                                                            person_type: res.data.person_type_name,
                                                            sex: res.data.sex
                                                        }
                            }

                            this.planData.date_from = res.data.date_from;
                            this.planData.start_time = res.data.start_time;
                            this.planData.end_time = res.data.end_time;
                            this.planData.id_shift_const_daytime = res.data.id_shift_const_daytime;
                            this.planData.shift_code = res.data.shift_code;

                            let checkedDate = moment(res.data.date_from, 'DD-MM-YYYY').format('YYYY,MM,DD')
                            const day = new Date(checkedDate);
                            const day1 = day.getDay();
                            this.planData.repeat_days = [];
                            this.planData.repeat_days.push(day1);
                            this.refresh_departments++;

                                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.step = 1;
                            this.get_copy_data = false
                            if(setPerson == true){
                                this.checkPerson();
                            }else{
                                this.loadingAdd = false;
                            }
                        });

            
        },

        checkPerson(){
            this.alert_class.openAlertConfirmation(this.trans('planning-do-you-want-to-copy-person', 291, {person_name: this.planData.person.name})).then(res => {
                if(res == false){
                    this.planData.person = null;
                }
            });
            this.loadingAdd = false;
        },

        countDownChangedSuccess(success_dismiss_count_down){
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        addAdditionals(){
            if(this.planData.department != null){
                var additional_functions_array = JSON.parse(this.planData.department.additional_functions)
                this.functions_additional = additional_functions_array;

                var functions = [];
                
                if(this.planData.ids_function_data_main[0] != null){
                    this.planData.ids_function_data_main = [this.planData.ids_function_data_main[0]]
                }
                if(this.functions_additional == null){
                    this.functions_additional = [];
                    return
                }

                for(var i=0; i<this.functions_additional.length; i++){
                    if(this.planData.ids_function_data_main.includes(this.functions_additional[i].id_function_data_main)){
                        functions = this.planData.ids_function_data_main.concat(this.functions_additional[i].ids_function_data_additional);
                        this.planData.ids_function_data_main = functions;
                        functions = [];
                        break;
                    }else if(this.planData.ids_function_data_main[0] != null){
                        this.planData.ids_function_data_main = [this.planData.ids_function_data_main[0]]
                    }
                }
                this.refresh++;
                this.checkQualifications();
            }
        },

        addAgain(){
            this.shifts = [];
            this.step = 1;
        },

        checkQualifications(){
            this.planData.ids_qualification_const_type = [];
            if(this.get_copy_data == false){
                if(this.planData.ids_function_data_main[0]){
                    let default_qualifications = null
                    if(this.planData.department.functions_qualifications){
                        let functions_qualifications = this.planData.department.functions_qualifications ? JSON.parse(this.planData.department.functions_qualifications) : [];
                        this.planData.ids_function_data_main.forEach(functionId => {
                            if (functions_qualifications && functions_qualifications[functionId]) {
                                this.planData.ids_qualification_const_type = [...this.planData.ids_qualification_const_type, ...functions_qualifications[functionId]];
                            }
                        });

                        default_qualifications = JSON.parse(this.planData.department.default_qualifications) == null ? [] : JSON.parse(this.planData.department.default_qualifications);
                        this.planData.ids_qualification_const_type = [...new Set([...this.planData.ids_qualification_const_type, ...default_qualifications])];
                    }
                }else{
                    this.planData.ids_qualification_const_type = [];
                }
                this.planData.refreshQualifications++;
            }
        }

    },
 
};
</script>
<style scoped>
.app-local-functions-error{
    margin-top: -22px;
}

.required-label{
    font-size: 0.8rem !important;
}
</style>