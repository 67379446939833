<template>
    <div>
        <b-overlay :show="loading" :opacity="0.6">
            
            <b-card>               
                
                <b-alert variant="light" show class="mb-0">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="d-flex align-items-center">
                            <div>
                                <b-icon icon="check-circle-fill" font-scale="3" class="mr-4"></b-icon>                        
                            </div>

                            <div class="w-100 align-items-center">           
                                <span class="align-middle font-weight-600 text-base">{{trans('sh-created-shift',292,{length : shifts.length, function_name : function_name})}}</span>  <br/>  
                                
                                <span class="app-local-person-data">
                                    <span class="app-local-link">{{client_location_name[0]}}</span> - 
                                    <span class="app-local-link" v-if="client_location_name[1]">{{client_location_name[1]}} | </span>
                                    <span class="app-local-link">{{planData.department.name}}</span>
                                </span>
                                
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <app-button type="primary" @click="addPerson()" v-if="this.register_person_on_portal == 1 && planData.person == null && shifts.length > 0">{{trans('register-flexworker',282)}}</app-button>

                            <div class="h-100 d-flex align-items-center" v-if="planData.person != null && this.register_person_on_portal == 1">
                                <img class="rounded-circle custom-avatar" v-if="planData.person.avatar != null" :src="planData.person.avatar" alt="Avatar" />
                                <img class="rounded-circle custom-avatar" v-else-if="planData.person.sex == 2" src="@/assets/images/avatars/female.jpg" alt="Avatar" />
                                <img class="rounded-circle custom-avatar" v-else  src="@/assets/images/avatars/male.jpg" alt="Avatar" /> 
                                
                                <div class="align-items-center ml-3">
                                    
                                    <b-row>
                                        <b-col>
                                            <div class="d-flex align-items-center">
                                                <h4 class="m-0 mr-2">{{planData.person.name}}</h4>
                                            </div>
                                        </b-col>
                                    </b-row>
            
                                    <b-row>
                                        <b-col end sm="12" lg="auto">    
                                            <span class="app-local-person-data"> {{planData.person.function_name}} </span>
                                        </b-col>
                                    </b-row>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </b-alert>

            </b-card> 

            <b-card no-body class="app-card-profile mt-3" v-if="shifts.length > 0">

                <b-row class="app-card-header">
                    <b-col start>
                        <div class="app-card-tittle app-local-title-height d-flex align-items-center">                       
                            <div> {{trans('services',280)}} ({{shifts.length}})</div>
                        </div>
                    </b-col>
                </b-row>
                
                
                <b-row class="ml-1 mr-1 pb-2 mt-3 app-local-row-header-font">
                    <b-col sm="1"  md="1" class="pr-0 app-local-checkbox-all">
                        <app-check-box v-model="selectedAll" value="1" unchecked-value="0" v-on:change="selectAll" />
                    </b-col>
                    <b-col sm="10" md="10">
                        <b-row>
                            <b-col cols="4">
                                {{trans('pl-date',288)}}
                            </b-col>
                            <b-col cols="4">
                                {{trans('hour',280)}}
                            </b-col>
                            <b-col cols="4">
                                {{trans('sh-type-of-service',292)}}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="1"  md="1" class="pr-0">
                        
                    </b-col>
                </b-row>

                <b-list-group flush class="app-local-group-list mb-2" >

                    <b-list-group-item  v-for="item in shifts" :key="item.id" class="app-local-group-item app-checklist-status-item" v-bind:class="{ 'app-checklist-danger':  item.status < 0 && planData.person != null, 'app-checklist-success': item.status == 1 && planData.person != null, 'app-checklist-warning': item.status == 0 && planData.person != null}">
                                
                        <b-row align-v="center">
                            <b-col sm="1" md="1" class="pr-0">
                                <app-check-box v-model="item.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(item)"/>
                            </b-col>

                            <b-col sm="10" md="10">

                                <b-row align-v="center">
                                    <b-col cols="4">
                                        {{setFormatDate(item.date_from)}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{planData.start_time}} - {{planData.end_time}}
                                    </b-col>
                                    <b-col cols="4">
                                        {{getDayTime(planData.id_shift_const_daytime)}}
                                    </b-col>
                                </b-row>

                                <b-row align-v="center" class="mt-1" v-if="item.status < 0">
                                    <b-col cols="auto">
                                        <span class="app-action-status-red-text">{{trans(item.error, 171)}}</span>
                                    </b-col>
                                </b-row>

                                <b-row v-if="planData.person != null && item.status == 0">
                                    <b-col cols="12">
                                        <b-alert variant="warning" show class="mt-3 mb-0">
                                            <div class="text-md-start">{{trans(item.error, 171)}}</div> 
                                        </b-alert> 
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="1" md="1" class="text-right">
                                
                                <b-dropdown variant="link"  no-caret right>
                                        <template #button-content>
                                            <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                        </template>
                                        
                                        <b-dropdown-item @click="copyShift(item)">{{trans('copy',282)}}</b-dropdown-item>
                                        <b-dropdown-item @click="cancel(item)">{{trans('pl-cancel-service',288)}}</b-dropdown-item>

                                    </b-dropdown> 
                            </b-col>

                        </b-row>

                    </b-list-group-item>

                </b-list-group>

                <b-dropdown no-caret toggle-class="app-button-multi" variant="secondary-grey" v-if="selectedIds.length > 0">
                    <template #button-content>
                         Actie ({{selectedIds.length}})                         
                    </template>
                        
                    <b-dropdown-item @click="cancel(null)">{{trans('pl-cancel-service',288)}}</b-dropdown-item>                             
                    
                </b-dropdown>

            </b-card>
            
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>    
        <offers-shifts-cancel-reason v-if="mShiftCancelReason.show" :show.sync="mShiftCancelReason.show" :args.sync="mShiftCancelReason.args" :result.sync="mShiftCancelReason.result" :return.sync="mShiftCancelReason.return" />
    </div>
</template>
<script>
import axios from "@axios";
import moment from 'moment';
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsCancelReason from '../../OffersShiftsCancelReason.vue';
export default {
    components: {
        OffersShiftsCancelReason
    },

    props:["planData", "shifts"],

    watch: {
        "mShiftCancelReason.result": {
            handler: function(val){
                if(this.mShiftCancelReason.result == 1){
                    this.loading = true;

                    var tmpArray = this.shifts;

                    this.mShiftCancelReason.args.ids_shift_data_main.forEach((item) => {

                        for(var i=0; i<tmpArray.length; i++){
                            if(item == tmpArray[i].id){
                                tmpArray.splice(i, 1);
                                break;
                            }
                        }

                    });

                    this.cShifts = tmpArray;
                    this.selectedIds = [];
                    this.selectedAll = 0;                    

                    this.loading = false;
                }
            }
        }
    },

    computed: {
        cShiftsPersonAddedCount : {
            get() { 
                    if(this.shifts != null){
                        return this.shifts.filter(({status}) => status >= 0).length;
                    }else{
                        return 0;
                    }
                },
        },

        cShifts: {
            get() {return this.shifts },
            set(value) { this.$emit('update:shifts', value) }
        }
    },

    created(){
        this.client_location_name = this.planData.client_location.name.split(' - ');
    },

    mounted() {
        this.function_name = this.shifts[0].function_name;
    },

    data() {
        return {
            loading: false,
            id_client_data_main: JSON.parse(localStorage.getItem('user-portal')).id_client_data_main,
            register_person_on_portal: JSON.parse(localStorage.getItem('user-portal')).register_person_on_portal,
            selectedAll: "0",
            selectedIds: [],
            alertClass: new AppAlerts(),
            function_name: "",
            mShiftCancelReason: { show: false, args: {
                                                        is_shift_done: null,
                                                        ids_shift_data_main: null, 
            }, result: 0, return: null},
        };
    },

    methods: {
        getDayTime(idShiftConstDaytime){

            if(idShiftConstDaytime == 1){
                return this.trans('day-part-morning',280);
            }else if(idShiftConstDaytime == 2){
                return this.trans('day-part-afternoon',280);
            }else if(idShiftConstDaytime == 3){
                return this.trans('day-part-day',280);
            }else if(idShiftConstDaytime == 4){
                return this.trans('day-time-evening',280);
            }else if(idShiftConstDaytime == 5){
                return this.trans('day-part-night',280);
            }else if(idShiftConstDaytime == 6){
                return this.trans('day-part-sleeping-shift',280);;
            }

        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },

        selectAll(){
            if(this.selectedAll == 0){
                this.shifts.forEach(element => {
                    element.selected = "0";
                    this.selectedIds.splice(this.selectedIds.indexOf(element.id), 1);
                });

            } else {
                this.selectedIds = [];
                this.shifts.forEach(element => {
                    element.selected = "1";
                    this.selectedIds.push(element.id);
                });
            }
        },

        selectedChanged(item){
            if(item.selected == "1"){
                this.selectedIds.push(item.id);
            }
            else if(item.selected == "0") {
                this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);
            }

            if(this.selectedAll == 1){
                this.shifts.forEach(element => {
                    if(element.selected == "0"){
                        this.selectedAll = 0;
                    }
                });
            }

            if(this.selectedAll == 0){
                var counter = 0;

                this.shifts.forEach(element => {
                    if(element.selected == "1"){
                        counter++;
                    }
                });

                if(counter == this.shifts.length){
                    this.selectedAll = 1;
                }
            }
        },

        cancel(item = null){

            this.alertClass.openAlertConfirmation((item == null ? this.trans('pl-services-cancel', 291) : this.trans('pl-service-cancel', 291))).then(res =>{

                if(res == true){
                    
                    let ids_shift_data_main = [];
                    if(item == null){//multi
                        ids_shift_data_main = this.selectedIds;
                    }else{//single
                        ids_shift_data_main = [item.id];
                    }

                    this.mShiftCancelReason.args.is_shift_done = 0;
                    this.mShiftCancelReason.args.ids_shift_data_main = ids_shift_data_main;
                    this.mShiftCancelReason.result = 0;
                    this.mShiftCancelReason.show = true;
                }

            });

        },

        copyShift(item){
            this.$router.push({ name: "planning-offers-shifts-add-copy", params: { id_shift_data_main: item.id } });
        },

        addPerson(){
            //var userRole = this.user_role;
            let ids_shift_data_main = [];
            ids_shift_data_main = this.shifts.map(a => a.id);
            let dates = this.shifts.map(a => this.setFormatDate(a.date_from));
            var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });

            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date }});
        }
    }

};
</script>

<style scoped>

.app-local-person-data{
    font-size: 14px;
}

.app-local-link{
    color: #174066 !important;
    font-weight: 400;
}

.app-local-link:hover{
    color: #8EC1F0 !important;
}

.app-local-link-profile{
    color: #174066 !important;
}

.app-local-link-profile:hover{
    color: #8EC1F0 !important;
}

.custom-avatar{
    height: 52px;
    width: 52px;
}

.app-local-group-list{
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-group-item{
    padding: 0.5rem 1rem;
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-group-list:hover{
    background-color: #f6f9fc;
    z-index: auto;
    text-decoration: none;
    cursor: pointer;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-checkbox-all{
    padding-left: 12px;
}

.app-local-warning{
    color: #d92d20;
}

.app-local-success{
    color: #12B76A;
}


.app-local-width{
    min-width:24px; 
    max-width:24px;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

.app-action-status-red-text{
    font-size: 12px;
    color: #f04438 !important;
}

</style>
