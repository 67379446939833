<template>
    <div>
        <b-row>
            <b-col cols="12">
                <app-select v-model="cPlanData.client_location" :label="trans('location',280)" type="getClientLocations" :refreshIndex.sync="refreshClientLocations" :args.sync="cPlanData.users_args" :validatorName="trans('location',280)" validatorRules="required" requiredMark/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <app-select class="w-100" v-model="cPlanData.department" :label="trans('department',280)" type="getDepartments" :refreshIndex.sync="refreshDepartments" :args.sync="cPlanData.users_args" :disable="cPlanData.client_location == null" :validatorName="trans('department',280)" :validatorRules="this.cPlanData.with_department != true ? 'required' : ''" requiredMark/>
            </b-col>
        </b-row>
    </div>
</template>
<script>

export default {

    props: {
        planData: {},
        isImport: { type: Boolean, default: false },
        loading: {type: Boolean}
    },

    computed: {
        cPlanData: {
            get() { return this.planData },
            set(value) { this.$emit('update:planData', value) }
        }
    },

    data() {
        return {           
            refreshDepartments: 1,
            refreshClientLocations: 1,
        };
    },

    created() {
        
    },

    watch: {
           
        "planData.client_location": {
            handler: function(val) {
                if (this.planData.client_location !== null && !this.loading) {
                    this.cPlanData.users_args.id_location_data_main = this.planData.client_location.value;
                    this.cPlanData.id_office_data_location = this.planData.client_location.id_office_data_location;

                    if (this.cPlanData.department  != null){
                        this.cPlanData.department.name = "";
                        this.cPlanData.department.value = null;
                        this.cPlanData.department.id_cao_data_main = null;
                        this.cPlanData.department.cao_source = null;
                        this.cPlanData.department = null;
                    }

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }

                    this.refreshDepartments++;
                }
            }         
        },

        "planData.department": {
            handler: function(val) {
               
                if (this.planData.department !== null && !this.loading) {

                    if (this.cPlanData.function  != null){
                        this.cPlanData.function.name = "";
                        this.cPlanData.function.value = null;
                        this.cPlanData.function = null;
                    }

                    if(this.cPlanData.department.value){
                        this.cPlanData.id_employment_const_type = this.cPlanData.department.zzp_preferences;
                        this.cPlanData.default_employment_data_main = this.cPlanData.department.zzp_preferences;
                    }else{
                        this.cPlanData.id_employment_const_type = null;
                        this.cPlanData.default_employment_data_main = null;
                    }

                    if(this.planData.department.ids_group_const_type != null){
                        this.cPlanData.ids_group_const_type = JSON.parse(this.planData.department.ids_group_const_type);
                    }

                    if(this.cPlanData.department !== null && this.cPlanData.department.id_cao_data_main != null){
                        this.cPlanData.refreshFunctions++;
                    }

                }
            }         
        }
    },
   
};
</script>
