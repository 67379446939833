import moment from "moment";
import translation from "./../../../../util/translations.js";


export default class ShiftsTypeClass{

    transObj = new translation();
    
    types = [
        { value: 1, label: this.transObj.trans('day-part-morning',280) },
        { value: 2, label: this.transObj.trans('day-part-afternoon',280) },
        { value: 3, label: this.transObj.trans('day-part-day',280) },
        { value: 4, label: this.transObj.trans('day-time-evening',280) },
        { value: 5, label: this.transObj.trans('day-part-night',280) },
        { value: 6, label: this.transObj.trans('day-part-sleeping-shift',280) }
    ];
    

    calculate(start_time_string, end_time_string, return_object = false) {

        if(start_time_string == null || end_time_string == null){
            return null;
        }

        var start_time = moment(start_time_string, "HH:mm:ss");
        var end_time = moment(end_time_string, "HH:mm:ss");
        
        if(start_time >= end_time){
            end_time = end_time.add(1, 'days');
        }

        if(start_time >= moment('5:00', "HH:mm:ss") && end_time <= moment('13:00', "HH:mm:ss")) {
            if (return_object) { return this.types[0]; } else { return 1;}
        } else if(start_time  >= moment('12:30', "HH:mm:ss") && end_time <= moment('18:00', "HH:mm:ss")) {
            if (return_object) { return this.types[1]; } else { return 2;}
        } else {

            var start_time_minutes = moment.duration(start_time).asMinutes();
            var end_time_minutes = moment.duration(end_time).asMinutes();

            if(moment(start_time_string, "HH:mm:ss") >= moment(end_time_string, "HH:mm:ss")){ //If we are moving to the next day, we count the time until the next day

                var day = Math.min(end_time_minutes, moment.duration(moment('18:00', "HH:mm:ss")).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('5:00', "HH:mm:ss")).asMinutes());
                var evening = Math.min(end_time_minutes, moment.duration(moment('01:00', "HH:mm:ss").add(1, 'days')).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('15:00', "HH:mm:ss")).asMinutes());
                var night = Math.min(end_time_minutes, moment.duration(moment('07:30', "HH:mm:ss").add(1, 'days')).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('22:00', "HH:mm:ss")).asMinutes());

                if(day < 0){
                    day = 0;
                }

                // count how many minutes in day part there are in the second day
                var day_part2 = Math.min(end_time_minutes, moment.duration(moment('18:00', "HH:mm:ss").add(1, 'days')).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('5:00', "HH:mm:ss").add(1, 'days')).asMinutes());

                if(day_part2 > 0){
                    day += day_part2;
                }


            }else{ //if there is no transition then count for two intervals, for example 0:00-1:00 and 15:00-00:00

                var day = Math.min(end_time_minutes, moment.duration(moment('18:00', "HH:mm:ss")).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('5:00', "HH:mm:ss")).asMinutes());
                var evening = Math.min(end_time_minutes, moment.duration(moment('01:00', "HH:mm:ss")).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('00:00', "HH:mm:ss")).asMinutes());
                var night = Math.min(end_time_minutes, moment.duration(moment('07:30', "HH:mm:ss")).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('00:00', "HH:mm:ss")).asMinutes());

                if(evening < 0){
                    evening = 0;
                }
                if(night < 0){
                    night = 0;
                }

                var evening_part2 = Math.min(end_time_minutes, moment.duration(moment('00:00', "HH:mm:ss").add(1, 'days')).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('15:00', "HH:mm:ss")).asMinutes());
                var night_part2 = Math.min(end_time_minutes, moment.duration(moment('00:00', "HH:mm:ss").add(1, 'days')).asMinutes()) - Math.max(start_time_minutes, moment.duration(moment('22:00', "HH:mm:ss")).asMinutes());

                if(evening_part2 > 0){
                    evening += evening_part2;
                }
                if(night_part2 > 0){
                    night += night_part2;
                }

            }

            day = day / (13 * 60);
            evening = evening / (10 * 60);
            night = night / (9.5 * 60);

            if(day >= evening && day >=  night) {
                if (return_object) { return this.types[2]; } else { return 3;}
            } else if(evening >=  night) {
                if (return_object) { return this.types[3]; } else { return 4;}
            } else {  
                if (return_object) { return this.types[4]; } else { return 5;}
            }
                
        }
    }
}