<template>
    <div>
        <b-card>               
            <div class="app-card-profile-title mb-3">{{trans('sh-plan-data',292)}}</div>
                               
            <b-row>
                <b-col md="6" xl="6">
                    <!---->
                    <app-date v-model="cPlanData.date_from" :label="trans('pl-date',288)" :validatorName="trans('pl-date',288)" validatorRules="required|date-check" :validatorCustomMessage="{ 'disable-date-past': trans('sh-date-not-in-past',293) }" :disabledDatesPass="1" requiredMark/>
                </b-col>
                <b-col md="3" xl="3">
                    <app-time v-model="cPlanData.start_time" :label="trans('sh-begin',292)" :validatorName="trans('sh-begin',292)" validatorRules="required" requiredMark/>
                </b-col>
                <b-col md="3" xl="3">
                    <app-time v-model="cPlanData.end_time" :label="trans('sh-end',292)" :validatorName="trans('sh-end',292)" validatorRules="required" requiredMark/>                        
                </b-col>
            </b-row>

            <b-row class="mt-3" >
                <b-col md="6" xl="6">    
                    <app-select :label="trans('planning-employment',288)" v-model="cPlanData.id_employment_const_type" reduceValue :clearable="cPlanData.default_employment_data_main ? false : true"  :options="employments_list" :disable="cPlanData.department == null ? true : false"/>
                </b-col>   

                <b-col md="6" xl="6">    
                    <b-alert variant="warning" show class="mb-0" v-if="cPlanData.id_employment_const_type != cPlanData.default_employment_data_main">
                        <div class="d-flex w-100 justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="w-100 align-items-center">           
                                    <span class="text-small">{{trans('sh-default-employment-relationship', 292, {name: employments_list.filter(item => item.value == cPlanData.default_employment_data_main)[0].name})}}</span>
                                </div>
                            </div>
                        </div>
                    </b-alert>
                </b-col>   
            </b-row>

            <b-row class="mt-3" >

                <b-col md="6" xl="6">
                    <app-radio-group :label="trans('sh-type-of-service',292)" v-model="cPlanData.id_shift_const_daytime" :validatorName="trans('sh-type-of-service',292)" validatorRules="required" :options="cDayTime" requiredMark/>
                </b-col>
            
            </b-row>

            <b-row class="mt-3">

                <b-col md="6" xl="6">
                    <app-multi-select v-model="cPlanData.ids_qualification_const_type" type="getQualifications" labelPosition="top" :refreshIndex="cPlanData.refreshQualifications"
                            :label="trans('kwalifications', 283)" :isAllOption="false" />
                </b-col>

                <b-col md="6" xl="6">
                    <app-multi-select v-model="cPlanData.ids_group_const_type" type="getTargetgroup" :args.sync="targetgroup_args" labelPosition="top" :refreshIndex="cPlanData.refreshTargetgroup"
                            :label="trans('target-group', 292)" :isAllOption="false" />
                </b-col>

            </b-row>

            <b-row class="mt-3" >

                <b-col md="6" xl="6">
                    <app-input v-model="cPlanData.shift_code" :label="trans('sh-shift-code',292)" validatorName="Shiftcode" mask="lettersE6-50"/>
                </b-col>

                <b-col md="6" xl="6">
                    <app-select :appendToBody="true" :clearable="true" v-model="cPlanData.pause_type" :options="pause_types" :label="this.trans('h-pause',305)"/>
                </b-col>

            </b-row>

            <b-row class="mt-3">
                <b-col cols="6">

                </b-col>
                <b-col cols="6">
                    <app-memo v-model="cPlanData.description" rows="5" :label="trans('c-notes',283)" :maxLength="10000"/>
                </b-col>
            </b-row>
           
        </b-card>    
      
    </div>
</template>
<script>

import moment from "moment";
import ShiftsTypeClass from "@app/Planning/Offers/Shifts/ShiftsTypeClass.js"
import { datecheck } from "@validations";
import axios from "@axios";

export default {
    components: {
    },

    props: {
        planData: {},
    },

    created(){
        this.checkSleepShiftParameters();
        this.cPlanData.refreshQualifications++;
        this.cPlanData.refreshTargetgroup++;
    },

    watch: {
        "planData.date_from": {
            handler: function(val) {
                let checkedDate = moment(this.planData.date_from, 'DD-MM-YYYY').format('YYYY,MM,DD')
                const day = new Date(checkedDate);
                const day1 = day.getDay();
                this.cPlanData.repeat_days = [];
                this.cPlanData.repeat_days.push(day1);
            }
        },
        
        "planData.start_time": {
            handler: function() {
                this.cPlanData.id_shift_const_daytime = this.shift_type_class.calculate(this.planData.start_time, this.planData.end_time)
            }
        },

        "planData.end_time": {
            handler: function() {
               this.cPlanData.id_shift_const_daytime = this.shift_type_class.calculate(this.planData.start_time, this.planData.end_time)
            }
        },
    },

    computed: {
        cPlanData:{
            get() { return this.planData },
            set(value) { this.$emit('update:planData', value) }
        },

        cDayTime:{
            get(){return this.day_time},
            set(value){this.$emit('update:day_time', value)}
        }
    },

    data() {
        return {
            shift_type_class :new ShiftsTypeClass(),
            days: [
                { value: 1, text: this.trans('monday',280)},
                { value: 2, text: this.trans('tuesday',280)},
                { value: 3, text: this.trans('wednesday',280) },
                { value: 4, text: this.trans('thursday',280) },
                { value: 5, text: this.trans('friday',280) },
                { value: 6, text: this.trans('saturday',280) },
                { value: 0, text: this.trans('sunday',280) },
            ],

            day_time: [
                { value: 1, text: this.trans('day-part-morning',280) },
                { value: 2, text: this.trans('day-part-afternoon',280) },
                { value: 3, text: this.trans('day-part-day',280) },
                { value: 4, text: this.trans('day-time-evening',280) },
                { value: 5, text: this.trans('day-part-night',280)},
                { value: 6, text: this.trans('day-part-sleeping-shift',280) }
            ],

            pause_types: [
                { value: 1, name: this.trans('no-option',280) },
                { value: 2, name: this.trans('yes-option',280) },
            ],

            targetgroup_args: {ids_department_data_main: [this.planData.department.id_department_data_main]},

            employments_list: [
                { value: 2, name: this.trans('sh-uzk',292) },
                { value: 3, name: this.trans('sh-zzp',292) },
                { value: 1, name: this.trans('sh-uzk-zzp',292) }
            ],
        };
    },

    methods: {

        checkSleepShiftParameters(){

            if(this.planData.department != null && this.planData.department.sleep_shift_parameters != null){

                let sleep_shift_param = JSON.parse(this.planData.department.sleep_shift_parameters);
                
                if(sleep_shift_param.sleep_shift == 1){//jesli się nalezy sleep shift
                    this.cDayTime[5].disabled = false;
                }else{
                    this.cDayTime[5].disabled = true;
                    if(this.cPlanData.id_shift_const_daytime == 6){
                        this.cPlanData.id_shift_const_daytime = this.shift_type_class.calculate(this.planData.start_time, this.planData.end_time)
                    }
                }

            }

        },
    }

};
</script>
