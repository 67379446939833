<template>
    <b-modal ref="modal-add" size="lg" v-model="computedShow" no-close-on-backdrop :title="trans('sh-withdraw', 292)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                
                <app-row-left-label :labelColumns="6" :label="trans('sh-withdraw-reason-label', 292)">
                    <app-select type="getCancelConstReason" v-model="id_cancel_const_reason"  validatorRules="required" :validatorName="trans('sh-withdraw-field', 292)" reduceValue />
                </app-row-left-label>

                <app-row-left-label :labelColumns="6" :label="trans('description', 280)">
                        <app-memo v-model="cancel_reason_description" :maxLength="200" lettersCounter :validatorName="trans('description', 280)" />
                </app-row-left-label>
                    
            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary" @click="cancel" :loading="loading">
                {{trans('save',282)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

    },

    mounted(){
    
    },

    watch: {},

    data() {
        return {
            loading: false,
            id_cancel_const_reason: null,
            cancel_reason_description: '',
        };
    },

    methods: {

        cancel(){

            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                } else {

                   
                    this.loading = true;

                    axios
                        .post("shifts/cancelNew", {
                            ids_shift_data_main: JSON.stringify(this.args.ids_shift_data_main),
                            id_cancel_const_reason: this.id_cancel_const_reason,
                            cancel_reason_description: this.cancel_reason_description,
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);                   
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });


                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
            this.$emit("update:result", 0); 
        },
    },
};
</script>
